import {
  LOAD_LAST_KYC,
  SAVE_KYC,
  SAVE_ADDRESS,
  SAVE_PROVINCES,
  SAVE_USER_REF,
  SAVE_DETAIL_PARTNERS,
} from '../../constants/ActionTypes';
import { fetchApi, fetchApiUpload } from '../../utils/api';
import { message } from 'antd';

export const updateProfile = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/user/update-profile`, 'post', payload);
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};

export const getRefInfo = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/user/get-ref`, 'post', payload);
    if (response.signal) {
      dispatch(actionSaveRefInfo(response.data));
    }
    return response;
  } catch (error) {
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};
export const confirmWithdrawGold = async (payload, dispatch) => {
  try {
    let response = await fetchApi(
      `/app/agency/confirm-withdraw-gold`,
      'post',
      payload,
    );
    return response;
  } catch (error) {
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};

export const sendGold = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/contacts/sendGold`, 'post', payload);
    return response;
  } catch (error) {
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};

export const getContact = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/contacts/list`, 'get', payload);
    if (response.signal === 1) {
      return response.data;
    }
    return response;
  } catch (error) {
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};
export const deleteContact = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/contacts/del`, 'post', payload);
    // if (response.signal === 1) {
    //     return response.data;
    // }
    return response;
  } catch (error) {
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};
export const actionSaveRefInfo = payload => ({
  type: SAVE_USER_REF,
  payload,
});
export const updatePassword = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/auth/update-password`, 'post', payload);
    const token = response.data.token;
    window.localStorage.setItem('token', token);
    // console.log('newToken', token);
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'Mật khẩu hiện tại không đúng!',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};
export const getLastKyc = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/kyc/get-last-kyc`, 'get', payload);
    if (response.signal === 1) {
      dispatch(actionSaveLastKyc(response.data));
    }
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};
export const findUser = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/contacts/find`, 'get', payload);
    if (response.signal === 1) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};

export const getUserBankName = async (payload, dispatch) => {
  try {
    let response = await fetchApi(
      `/app/user/get-user-bank-name`,
      'post',
      payload,
    );
    if (response.signal === 1) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};

export const submitPartners = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/submit/partners`, 'post', payload);
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
    };
  }
};

export const addUpdateAddress = async (payload, dispatch) => {
  try {
    let response = await fetchApi(
      `/app/user/add-update-address`,
      'post',
      payload,
    );
    if (response.signal === 1) {
      message.success('Success !');
    }
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
    };
  }
};
export const deleteAddress = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/user/del-address`, 'post', payload);
    if (response.signal === 1) {
      message.success('Success !');
    }
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
    };
  }
};
export const postKyc = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/kyc/post`, 'post', payload);
    if (response.signal === 1) {
      dispatch(actionSaveClientKyc(response.data));
    }
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
    };
  }
};

export const actionSaveLastKyc = payload => ({
  type: LOAD_LAST_KYC,
  payload,
});

export const actionSaveClientKyc = payload => ({
  type: SAVE_KYC,
  payload,
});

export const getAllAddress = async (payload, dispatch) => {
  try {
    let response = await fetchApi(`/app/user/get-list-address`, 'get', payload);
    if (response.signal === 1) {
      dispatch(actionSaveAddress(response.data));
    }
    return response;
  } catch (error) {
    console.log(error);
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
      data: {
        success: true,
      },
    };
  }
};
export const uploadOneFile = async payload => {
  try {
    const response = await fetchApi(`/api/upload-base64`, 'post', payload);

    if (response.code === 400) {
      message.error(response?.message);
      setTimeout(() => {
        message.destroy();
      }, 2000);
      return;
    }
    return response?.data;
  } catch (error) {
    alert(error || error?.message);
    console.log('uploadOneFileError', error);
  }
};

export const uploadAvatarFile = async (payload, dispatch) => {
  try {
    const response = await fetchApiUpload(`/api/uploadImage`, 'post', payload);

    if (response.code === 400) {
      message.error(response?.message);
      setTimeout(() => {
        message.destroy();
      }, 2000);
      return;
    }
    return response?.data?.filePaths?.file;
  } catch (error) {
    alert(error || error?.message);
    console.log('uploadOneFileError', error);
  }
};

export const actionSaveAddress = payload => ({
  type: SAVE_ADDRESS,
  payload,
});

export const loadProvinces = async (payload, dispatch) => {
  try {
    const response = await fetchApiUpload(
      `/app/other/provinces`,
      'get',
      payload,
    );

    if (response.code === 200) {
      dispatch(actionSaveProvinces(response.data));
    }
    return response;
  } catch (error) {
    console.log('uploadOneFileError', error);
  }
};

export const actionSaveProvinces = payload => ({
  type: SAVE_PROVINCES,
  payload,
});

export const loadDetailPartners = async (payload, dispatch) => {
  try {
    const response = await fetchApiUpload(
      `/app/submit/detail-partners`,
      'get',
      payload,
    );

    if (response.code === 200) {
      dispatch(actionSaveDetailPartners(response.data));
    }
    return response;
  } catch (error) {
    console.log('loadDetailPartners', error);
  }
};

export const actionSaveDetailPartners = payload => ({
  type: SAVE_DETAIL_PARTNERS,
  payload,
});
